<template>
  <div>
    <div class="mx-3 mt-6">
      <Card class="mt-6 p-5">
        <div class="flex mb-6" style="justify-content: flex-end">
          <Button class="text-white" @click="handleClick" :background="appearance.buttonColor || defaultBTNColor">
            New Job Level
          </Button>
        </div>
        <template v-if="!showError" v-slot:footer>
          <card-footer printcard reloadcard @print="print" @reload="reload()" @sortType="handleSort($event)"
            @searchResult="handleSearch($event)" />
        </template>
      </Card>
    </div>
    <div v-if="!showError" class="p-3">
      <sTable :headers="levelHeader" :items="levelArray" :loading="loadingData" aria-label="leveltable"
        :pagination-list="metaData" @page="handlePage($event)" @itemsPerPage="handleItemsPerPage($event)" page-sync
        id="printMe" style="width: 100%" class="w-full" has-number>
        <template v-slot:item="{ item }">
          <span v-if="item.category">{{
            item.data.category ? item.data.category.name : "Nil"
          }}</span>
          <span v-if="item.name">{{ item.data.name }}</span>
          <span v-if="item.tags" class="flex flex-wrap">
            <div v-for="(tag, index) in item.data.tags" :class="`tag_${tag.name}`" class="p-1 mr-2 tag" :key="index">
              {{ tag.name }}
            </div>
          </span>

          <span v-else-if="item.id">
            <Menu left top="-150" margin="40" class="my-2 p-0">
              <template v-slot:title>
                <icon icon-name="more_icon" size="xs" />
              </template>
              <div style="width: 116px; height: 81px" class="py-3 px-2">
                <div class="flex py-1 px-3 more h-8 cursor-pointer" @click="handleEdit(item.data.id)">
                  <icon icon-name="edit" class-name="text-blueCrayola mr-2" size="xsm" />
                  <p class="pt-1">Edit</p>
                </div>
                <div class="flex px-3 py-1 more h-8 cursor-pointer" @click="confirmDelete(item.data.id)">
                  <icon icon-name="delete" class-name="text-desire mr-2" size="xsm" />
                  <p class="pt-1">Delete</p>
                </div>
              </div>
            </Menu>
          </span>
        </template>
      </sTable>
    </div>
    <div v-else>
      <error-page />
    </div>

    <ValidationObserver rules="required" v-slot="{ handleSubmit }">
      <RightSideBar v-if="openModal" @submit="
        editMode ? handleSubmit(updateLevel) : handleSubmit(createLevel)
        " @close="openModal = false" submit="Save" :loading="loading" :button-class="`bg-dynamicBackBtn text-white`"
        :disabled-button="disableBtn">
        <template v-slot:title>
          <p class="text-darkPurple">
            {{ editMode ? "Edit Job Level" : "Add New Job Level" }}
          </p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple">
            Which of these best describe the Job Level?
          </p>
          <div class="flex w-full justify-between items-center">
            <div class="flex flex-col gap-3">
              <radio-button :options="categoryArray" col-span="col-span-12" :editRadio="true"
                class="m-4 text-darkPurple" @change="handleRadioChange" @editedCategory="handleCategoryChange($event)"
                row-gap="gap-y-5" v-model="level.categoryId" :editOption="editableOption" :rules="['required']" />
            </div>
            <span class="w-auto flex flex-col gap-5">
              <span class="flex justify-between items-center w-full gap-3" style="height: 25px"
                v-for="(options, index) in categoryArray" :key="index">
                <span class="flex justify-between items-center w-full gap-4"
                  v-if="options.id === selectedCategory.id && Object.keys(editableOption).length === 0">
                  <span class="flex w-auto gap-1 cursor-pointer" @click="editableOption = selectedCategory">
                    <icon icon-name="icon-edit" size="xms" class="inline-block text-flame" />
                    <p class="text-sm font-semibold mx-1 text-flame">Rename</p>
                  </span>
                  <span class="w-auto cursor-pointer" @click="handleDeleteJobLevelCategory(selectedCategory.id)" v-if="
                    options.id === selectedCategory.id && options.isCustom
                  ">
                    <icon icon-name="icon-trash" size="xms" class="text-desire" />
                  </span>
                </span>

                <span class="flex justify-between items-center w-full gap-4"
                  v-if="options.id === selectedCategory.id && Object.keys(editableOption).length > 0">
                  <span class="flex w-auto gap-1 cursor-pointer" @click="updateLevelCategory(selectedCategory)">
                    <p class="text-sm font-semibold mx-1 text-flame">Save</p>
                  </span>
                  <span class="w-auto cursor-pointer" @click="cancelCategoryUpdate(selectedCategory)">
                    <p class="text-sm font-semibold mx-1">Cancel</p>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div class="flex flex-col w-full gap-2 my-1">
            <div class="flex w-auto gap-2 mx-4 cursor-pointer" v-if="!addCustomLevel" @click="addCustomLevel = true">
              <icon icon-name="icon-plus" class="text-flame" size="xms" />
              <p class="text-sm text-flame font-semibold">Add Custom</p>
            </div>

            <div class="flex w-full flex-col gap-5" v-else>
              <divider class="-my-1" />
              <c-text placeholder="--Enter--" variant="w-full h-10" v-model="customCategory"
                style="height: 40px !important" />
              <div class="flex flex-end justify-end gap-3">
                <span class="flex w-auto gap-1 cursor-pointer" @click="addCustomCategory()">
                  <p class="text-sm font-semibold mx-1 text-flame">Save</p>
                </span>
                <span class="w-auto cursor-pointer" @click="addCustomLevel = false">
                  <p class="text-sm font-semibold mx-1">Cancel</p>
                </span>
              </div>
            </div>
          </div>
        </template>
        <div>
          <p class="text-darkPurple">Job Level Details</p>
          <div class="grid grid-cols-12 gap-7 text-darkPurple" style="margin-top: 1rem">
            <div class="col-span-12">
              <ComboBox :options="selectedTags" @addSelectedTags="formattedTags($event)"
                @removeTag="removedTags($event)" @addTag="saveTag($event)" />
              <!-- <c-select
              placeholder="--Select--"
              :options="tagArray"
              @input="checkTag"
              variant="w-full"
            /> -->
            </div>
            <div class="col-span-12">
              <c-text placeholder="--Enter Level ID--" variant="w-full h-10" label="Level ID"
                style="height: 40px !important" v-model="level.name" :rules="['required']" />
            </div>
          </div>
        </div>
      </RightSideBar>
    </ValidationObserver>

    <Modal v-if="dialogDel">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Level?
          </p>
          <div class="flex">
            <Button :background="appearance.buttonColor || defaultBTNColor" class="text-white" @click="deleteLevel">
              Yes
            </Button>
            <Button background-color="none" class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDel = false">
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>

    <Modal v-if="dialogDelCategory">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to delete this Category?
          </p>
          <div class="flex">
            <Button :background="appearance.buttonColor || defaultBTNColor" class="text-white"
              @click="deleteJobLevelCategory">
              Yes
            </Button>
            <Button background-color="none" class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="dialogDelCategory = false">
              Cancel
            </Button>
          </div>
        </div>
      </Card>
    </Modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import ErrorPage from "@/modules/Admin/error403";
import Menu from "@/components/Menu";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";
import Modal from "@/components/Modal";
import ComboBox from "@/components/ComboBox";
import Divider from "@/components/divider";

export default {
  name: "JobLevel",
  components: {
    ValidationObserver,
    ComboBox,
    CText,
    STable,
    Button,
    ErrorPage,
    Card,
    CardFooter,
    Menu,
    Divider,
    RightSideBar,
    RadioButton,
    Modal,
  },

  data() {
    return {
      levelArray: [],
      openModal: false,
      addCustomLevel: false,
      dialogDel: false,
      dialogDelCategory: false,
      selectedCategoryId: "",
      loadingData: true,
      editMode: false,
      showError: false,
      loading: false,
      tagArray: [],
      metaData: {},
      customCategory: null,
      disableBtn: false,
      hierarchy: [],
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      isActive: null,
      sortType: null,
      chipTag: [],
      level: {
        name: "",
        categoryId: null,
      },
      editableOption: {},
      levelId: "",
      categoryArray: [],
      selectedCategory: {},
      levelHeader: [
        { title: "CATEGORY", value: "category" },
        { title: "job level id", value: "name", width: "20" },
        { title: "DESCRIPTION", value: "tags", width: "50" },
        { title: "", value: "id", image: true },
      ],
      selectedTags: [],
    };
  },
  methods: {
    cancelCategoryUpdate(categoryObj) {
      const category = this.categoryArray.find((item) => item.id === categoryObj.id);
      this.selectedCategory = { ...category };
      this.editableOption = {};
      console.log(this.selectedCategory)
    },
    formattedTags(data) {
      this.tagArray.push(data);
    },
    handleDeleteJobLevelCategory(selectedId) {
      this.selectedCategoryId = selectedId;
      this.dialogDelCategory = true;
    },
    deleteJobLevelCategory() {
      this.$_deleteCategory(this.selectedCategoryId)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.dialogDelCategory = false;
          this.selectedCategoryId = "";
          this.categoryArray = [];
          this.getCategory();
        })
        .catch((error) => {
          this.$toasted.success(error.response.data.message, {
            duration: 5000,
          });
        });
    },
    removedTags(data) {
      this.tagArray = this.tagArray.filter((item) => item.id !== data);
    },
    saveTag(data) {
      const payload = {
        name: data,
      };
      this.$_addTagCategory(payload, this.selectedCategory.id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getCategory();
        })
        .catch((error) => {
          this.$toasted.success(error.response.data.message, {
            duration: 5000,
          });
        });
    },
    setNewTag() {
      if (this.selectedCategory.id) {
        const category = this.categoryArray.find(
          (item) => item.id === this.selectedCategory.id
        );
        if (category) {
          if (this.tagArray.length === 0) {
            this.tagArray = category.tags.map((tag) => {
              return { ...tag, checked: true };
            });

            this.selectedTags = category.tags.map((tag) => {
              return { ...tag, checked: true };
            });
          } else {
            const newTags = category.tags
              .filter(
                (categoryItem) =>
                  !this.tagArray.some(
                    (tagItem) => tagItem.id === categoryItem.id
                  )
              )
              .map((item) => ({ ...item, checked: false }));

            this.tagArray.push(...newTags);
            this.selectedTags.push(...newTags);
          }
        }
      }
    },
    updateLevelCategory(levelData) {
      if (levelData.name !== "") {
        const payload = {
          name: levelData.name,
        };
        this.$_updateLevelCategory(payload, levelData.id)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.editableOption = {};
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      }
      else {
        this.$toasted.error("Job Category field is required", {
          duration: 5000,
        });
      }
    },
    handleRadioChange(value) {
      const category = this.categoryArray.find((item) => item.id === value);
      this.selectedCategory = { ...category };
      if (category) {
        this.tagArray = category.tags.map((tag) => {
          return { ...tag, checked: true };
        });

        this.selectedTags = category.tags.map((tag) => {
          return { ...tag, checked: true };
        });
      }
    },
    async confirmDelete(id) {
      try {
        await this.$handlePrivilege("jobLevels", "deleteJobLevel");
        this.levelId = id;
        this.dialogDel = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async deleteLevel() {
      try {
        this.dialogDel = false;
        this.loadingData = true;
        await this.$_deleteLevel([this.levelId]);
        this.getLevels("");
        this.$toasted.success("Level deleted successfully", { duration: 3000 });
      } catch (error) {
        this.loadingData = false;
        this.$toasted.error("Level not deleted", { duration: 3000 });
      }
    },
    async handleClick() {
      try {
        await this.$handlePrivilege("jobLevels", "addNewJobLevel");
        this.chipTag = [];
        this.level = { name: "", categoryId: null };
        this.selectedCategory = {};
        this.editableOption = {};
        this.tagArray = [];
        this.editMode = false;
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleEdit(id) {
      try {
        await this.$handlePrivilege("jobLevels", "editJobLevel");
        this.loading = true;
        this.openModal = true;
        this.editMode = true;
        this.$_getOneLevel({ levelId: id }).then((response) => {
          this.level.categoryId = response.data.level.category.id;
          this.handleRadioChange(response.data.level.category.id);
          this.level.name = response.data.level.name;
          this.loading = false;
          this.levelId = id;
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    addCustomCategory() {
      if (this.customCategory) {
        const payload = {
          name: this.customCategory,
          isCustom: true,
        };

        this.$_addCategory(payload).then((response) => {
          this.$toasted.success("Category successfully", { duration: 3000 });
          this.categoryArray = [];
          this.addCustomLevel = false;
          this.customCategory = "";
          this.getCategory();
        });
      } else {
        this.$toasted.error("Custom Category field is required", {
          duration: 3000,
        });
      }
    },
    async updateLevel() {
      this.loadingData = true;
      const levelObject = {
        levelTags: [],
        tags: [],
      };
      this.disableBtn = true;
      levelObject.orgId = this.$AuthUser.orgId;
      levelObject.levelId = this.levelId;
      levelObject.name = this.level.name;
      levelObject.categoryId = this.level.categoryId;
      this.tagArray.forEach((element) => {
        if (element.id) {
          levelObject.levelTags.push({ tagId: element.id });
        } else {
          levelObject.newTags.push(element);
        }
      });
      try {
        await this.$_updateLevel(levelObject);
        this.getLevels("");
        this.openModal = false;
        this.$toasted.success("Level edited successfully", { duration: 3000 });
        this.disableBtn = false;
        this.bgButton = "bg-flame";
      } catch (err) {
        this.$toasted.error("Level not edited successfully", {
          duration: 3000,
        });
        this.disableBtn = false;
        throw new Error(err);
      }
    },
    async createLevel() {
      const levelObject = {
        level: {
          levelTags: [],
        },
        tags: [],
      };
      this.disableBtn = true;

      levelObject.level.orgId = this.$AuthUser.orgId;
      levelObject.level.name = this.level.name;
      levelObject.level.categoryId = this.level.categoryId;
      this.tagArray.forEach((element) => {
        if (element.id) {
          levelObject.level.levelTags.push({ tagId: element.id });
        } else {
          levelObject.tags.push(element);
        }
      });

      if (!levelObject.level.categoryId) {
        this.$toasted.error("Category not selected", {
          duration: 3000,
        });
        this.disableBtn = false;
        return;
      }

      if (levelObject.level.levelTags.length === 0) {
        this.$toasted.error("Job Level details is required", {
          duration: 3000,
        });
        this.disableBtn = false;
        return;
      }

      try {
        await this.$_createLevel(levelObject);
        this.loadingData = true;
        this.chipTag = [];
        this.level.categoryId = null;
        this.selectedTags = [];
        this.getLevels("");
        this.openModal = false;
        this.$toasted.success("Level created successfully", { duration: 3000 });
        this.disableBtn = false;
        this.bgButton = "bg-flame";
      } catch (error) {
        this.chipTag = [];
        this.level.categoryId = null;
        this.openModal = false;
        this.$toasted.error("Level not created successfully", {
          duration: 3000,
        });
        this.disableBtn = false;
      }
    },
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getLevels(`${sort}${search}${pageNumber}${itemPage}`);
    },
    checkTag(value) {
      const tag = this.tagArray.find((element) => element.id === value);
      if (tag) {
        const isPresent = this.chipTag.find((element) => element.id === tag.id);
        if (isPresent) {
          return;
        }
        this.chipTag.push({ name: tag.name, id: tag.id });
      } else {
        this.chipTag.push({ name: value, orgId: this.$AuthUser.orgId });
      }
    },
    async print() {
      await this.$htmlToPaper("printMe");
    },
    handleSearch(searchWord) {
      this.loadingData = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleSort(sortType) {
      this.loadingData = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    reload() {
      this.getLevels("");
    },
    async getLevels(payload) {
      this.loadingData = true;
      try {
        const response = await this.$_getLevels(payload);
        this.levelArray = response.data.levels;
        this.metaData = response.data.meta;
        this.loadingData = false;
      } catch (error) {
        throw new Error(error);
      }
    },
    async getCategory() {
      try {
        const response = await this.$_getCategory();
        this.categoryArray = response.data.categories;
        this.categoryArray = this.categoryArray.map((element) => ({
          name: element.name,
          value: element.id,
          radioName: "job",
          ...element,
        }));
        this.setNewTag();
      } catch (error) {
        throw new Error(error);
      }
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  async mounted() {
    try {
      await this.$handlePrivilege("jobLevels", "viewRegister");
      this.getLevels("");
      this.getCategory();
      this.showError = false;
    } catch (error) {
      this.showError = true;
      this.loadingData = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-radius {
  border-radius: 7px;
}

.tag {
  border-radius: 5px;
  background: rgba(135, 142, 153, 0.15);
  color: #333333;

  &_Authority {
    background: rgba(47, 128, 237, 0.15);
    color: #2176ff;
  }

  &_Leadership {
    background: rgba(241, 90, 41, 0.15);
    color: #f15a29;
  }

  &_Strategy {
    background: rgba(19, 181, 106, 0.15);
    color: #27ae60;
  }

  &_Oversight {
    background: rgba(242, 201, 76, 0.15);
    color: #e99323;
  }

  &_GoalSetting {
    background: rgba(135, 142, 153, 0.15);
    color: #333333;
  }
}
</style>